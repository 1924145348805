<template>
  <div>
    <CCard>
      <CCardBody>
        <CElementCover v-if="isLoading">
          <CSpinner size="5xl" color="info"/>
        </CElementCover>
        <div v-else>
          <div v-if="subscriptionInfo && normalizedInfo.status && normalizedInfo.status !== 'pending'">
            <p><strong>Your subscription plan:</strong> {{ normalizedInfo.name }}</p>
            <p><strong>Price:</strong> {{ normalizedInfo.price }} /{{ normalizedInfo.period }}</p>
            <p><strong>Start date:</strong> {{ normalizedInfo.start_date }}</p>
            <p><strong>Status:</strong>&nbsp;
              <CBadge :color="getColorByStatus(normalizedInfo.status + (isPaymentStopped(normalizedInfo) ? ', payments stopped' : ''))">
                {{normalizedInfo.status}}<template v-if="isPaymentStopped(normalizedInfo)">, payments stopped</template>
              </CBadge>
            </p>
            <p><strong>Setup Code:</strong> {{ normalizedInfo.setup_code }}<span class="copy-text-btn"
                                                                                 @click="copyToClipboard(normalizedInfo.setup_code)"><CIcon name="cil-copy"/></span></p>
            <p><strong>Customer ID:</strong> {{ normalizedInfo.network_id }}<span class="copy-text-btn"
                                                                                  @click="copyToClipboard(normalizedInfo.network_id)"><CIcon name="cil-copy"/></span></p>
            <p><a target="_blank" :href="normalizedInfo.update_card_url">Update Payment Info</a></p>
            <p class="bottom-link" v-if="!normalizedInfo.sub_cancel_at && normalizedInfo.period !== 'lifetime'"><router-link to="/cancel">Cancel Subscription</router-link></p>
          </div>
          <div v-else>You currently don't have any subscription.</div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import SubscriptionService from "@/services/SubscriptionService";
import dayjs from "dayjs";

export default {
  name: 'Dashboard',
  components: {},
  data () {
    return {
      isLoading: false,
      subscriptionInfo: null,
    }
  },
  methods: {
    async fetchSubInfo() {

      this.isLoading = true;

      try {
        const response = await SubscriptionService.getInfo();
        this.subscriptionInfo = response.data;
      } finally {
        this.isLoading = false;
      }
    },
    getColorByStatus(status) {
      switch(status) {
        case 'active': return 'success';
        case 'suspended': return 'secondary';
        case 'pending_deposit': return 'warning';
        case 'cancelled': return 'danger';
        default: return 'primary';
      }
    },
    isPaymentStopped(item) {
      return item.status === 'active' && item.sub_cancel_at;
    },
    async copyToClipboard(value) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$toast.success('The value has been copied to clipboard.')
        return navigator.clipboard.writeText(value);
      }
      return Promise.reject('The Clipboard API is not available.');
    },
  },
  computed: {
    normalizedInfo() {
      return {
        name: this.subscriptionInfo.sub_plan_details.name || '',
        status: this.subscriptionInfo.sub_status || '',
        sub_cancel_at: this.subscriptionInfo.sub_cancel_at || '',
        gateway: this.subscriptionInfo.sub_gateway || '',
        setup_code: this.subscriptionInfo.setup_code || '',
        network_id: this.subscriptionInfo.partition_key || '',
        price: this.subscriptionInfo.sub_plan_details.price || 0.00,
        period: this.subscriptionInfo.sub_plan_details.interval || '',
        start_date: dayjs.unix(this.subscriptionInfo.sub_started_at).format('MMM DD, YYYY'),
        update_card_url: `${process.env.VUE_APP_CHECKOUT_APP_URL}/payment-profile?network_id=${this.subscriptionInfo.partition_key}&admin_key=${this.subscriptionInfo.admin_key}`,
      }
    }
  },
  async mounted() {
    const userSessionData = await Auth.currentAuthenticatedUser();
    const userGroups = userSessionData.signInUserSession.idToken.payload['cognito:groups'] || [];

    if(userGroups.includes('admin')) return;

    await this.fetchSubInfo();
  }
}
</script>

<style lang="scss" scoped>
.bottom-link {
  position: absolute;
  right: 20px;
  bottom: 20px;
  margin-bottom: 0;

  a {
    color: #3c4b64;
  }
}

.copy-text-btn {
 cursor: pointer;
  margin-left: 3px;
}
</style>