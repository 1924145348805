import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class SubscriptionService extends AbstractService {
	async suspend(network_id) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

		return this.api.post('/sub/suspend',
			{
				network_id: network_id
			},
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
	async cancel(network_id, reason, isInstant = false) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

		const postParams = {
			network_id: network_id,
			sub_cancellation_reason: reason
		};

		if(isInstant) {
			postParams.instant_cancel = 1;
		}

		return this.api.post('/sub/cancel',
			postParams,
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}

	async getInfo() {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

		return this.api.get('/sub',
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
}