import axios from "axios";
import Vue from 'vue'

export class AbstractService {
    constructor() {
        this.api = axios.create({
            baseURL: process.env.VUE_APP_MY_API_URL,
        });

        this.api.interceptors.response.use(
            (response) => {
                if (response.status === 200 || response.status === 201) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response);
                }
            },
            (error) => {
                const errorMessage = error?.response?.data?.message || 'Something wrong'

                Vue.$toast.error(errorMessage);

                return Promise.reject(error);
            }
        )
    }
}